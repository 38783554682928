<template>
  <b-card>
    <div
      v-show="showLoading"
      class="text-center text-primary my-2"
    >
      <b-spinner class="align-middle" />
      <strong>This will only take a moment. Please wait...</strong>
    </div>
    <b-tabs
      v-if="!showLoading"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <template
        v-for="(data,name, index) in discountPlan"
        v-if="name!='OtherInfo' && name!='Plan' && name!='discount'"
      >
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ name }}</span>
          </template>
          <b-card no-body>
            <b-table-simple
              hover
              striped
              bordered
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Operator [Code]</b-th>
                  <b-th>Operator type</b-th>
                  <b-th>Commission For#{{ activeUserInfo.loginid }}</b-th>
                  <b-th>Commission type #Downline</b-th>
                  <b-th>Surcharge/Discount</b-th>
                  <b-th>In</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-show="showLoading"
                  class="b-table-busy-slot"
                >
                  <b-td colspan="11">
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle" />
                      <strong>Loading...</strong>
                    </div>
                  </b-td>
                </b-tr>
                <template v-if="data.length>0 && !showLoading">
                  <b-tr
                    v-for="(tr, indextr) in data"
                    :key="indextr"
                  >
                    <b-td>
                      {{ tr.operators }}
                    </b-td>
                    <b-td>{{ tr.operators_type }}</b-td>
                    <b-td>
                      {{ tr.commission }}
                    </b-td>
                    <b-td>
                      {{ tr.commission_type }}
                    </b-td>
                    <b-td>
                      <ValidationObserver>
                        <validation-provider
                          #default="{ errors }"
                          :rules="`isBigger:${tr.selfcommi}`"
                        >
                          <b-form-input
                            v-model="discountdata[tr.operators_id]['discount_surcharge']"
                            type="number"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >Sorry ! you can't distribute more discount then self {{ tr.selfcommi }}%</small>
                        </validation-provider>
                      </ValidationObserver>
                    </b-td>
                    <b-td>
                      <p v-if="tr.discount_surcharge_displaytype == 'Currency'">
                        MYR
                      </p>
                      <p v-else>
                        {{ tr.discount_surcharge_displaytype }}
                      </p>
                    </b-td>
                  </b-tr>
                </template>
                <template v-else-if="!showLoading">
                  <b-tr rowspan="10">
                    <b-td
                      colspan="11"
                      class="text-center"
                    >
                      No data avilable
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-tab>
      </template>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="FileTextIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Save Slab Name And Plan</span>
        </template>
        <b-card>
          <b-row>
            <b-col
              cols="6"
              class="mb-2"
            >
              <b-form-group
                label="Slab Name"
                label-for="slab_id"
              >
                <b-form-input
                  id="slab_id"
                  v-model="slab_name"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              class="mb-2"
            >
              <b-form-group
                label="Plan"
                label-for="plan"
              >
                <v-select
                  id="plan"
                  v-model="plan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="planOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12  justify-content-end"
              md="6"
            >
              <b-button
                class="d-flex justify-content-end"
                :disabled="loding"
                variant="primary"
                @click="updatePlan"
              >
                <b-spinner
                  v-show="loding"
                  small
                />
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab @click="$router.push('/discount/plans')">
        <template #title>
          <feather-icon icon="ArrowLeftIcon" />
          <span
            class="font-weight-bold"
            @click="$router.push('/discount/plans')"
          >Back</span>
        </template>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BTabs, BTab, BThead, BTr, BTh, BTd, BTbody, BTfoot, BTableSimple, BFormInput, BButton, BFormGroup, BSpinner, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SecureLS from 'secure-ls'
import UserService from '../../services/user.service'
import JwtService from '../../common/jwt.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BTableSimple,
    BFormInput,
    BButton,
    BSpinner,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      discountPlan: [],
      prepaid: [],
      postpaid: [],
      games: [],
      pin: [],
      government: [],
      product_registration: [],
      slab_name: '',
      plan: '',
      showLoading: false,
      loding: false,
      planOptions: [
        { label: 'Select Ranks', value: '' },
        { label: 'Master Plan', value: '17' },
        { label: 'Reseller Plan', value: '19' },
        { label: 'Dealer Plan', value: '20' },
      ],
      discountdata: [],
      isMounted: false,
    }
  },
  computed: {
    activeUserInfo() {
      const user = JwtService.getUser()
      return user
    },
  },
  mounted() {
    this.isMounted = true
    this.getDiscountPlan()
    extend('isBigger', {
      params: ['target'],
      validate(value, { target }) {
        return target >= value
      },
    })
  },
  methods: {
    updatePlan() {
      const ls = new SecureLS({
        encodingType: 'aes',
        isCompression: false,
      })
      this.loding = true
      UserService.addAndEditDiscountPlan({
        slab_id: ls.get('edit_plan_id'), operators: this.discountdata, plan_id: this.plan.value, slab_name: this.slab_name,
      }).then(
        response => {
          this.loding = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Plan',
              text: response.message,
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        },
        error => {
          this.loding = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        },
      )
    },
    getDiscountPlan() {
      const ls = new SecureLS({
        encodingType: 'aes',
        isCompression: false,
      })

      this.showLoading = true
      UserService.getDiscountPlan({ slab_id: ls.get('edit_plan_id') }).then(
        response => {
          this.discountPlan = response
          this.slab_name = response.Plan[0].slab_name
          this.discountdata = response.discount
          this.plan = { value: response.Plan[0].plan_id, label: response.Plan[0].plan_name }
          this.showLoading = false
        },
        error => {
          this.showLoading = false
        },
      )
    },
  },
}

</script>
